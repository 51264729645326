import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Contact} from '../domain/contact';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService extends ApiService<Contact> {

  constructor(http: HttpClient) {
    super('contact', http);
  }
}
