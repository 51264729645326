import {Component, HostListener, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs/internal/Subject";
import {menuHeightBig, menuHeightSmall} from "../../constants";

@Component({
  selector: 'bridge-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output()
  menuClicked = new Subject<string>();

  menuHeight = menuHeightBig;
  menuOpen = false;

  constructor() {
  }

  ngOnInit() {
  }

  scrollTo(tag: string): void {
    this.menuOpen = false;
    this.menuClicked.next(tag);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event): void {
    if (document.documentElement.scrollTop > 80) {
      this.menuHeight = menuHeightSmall;
    } else {
      this.menuHeight = menuHeightBig;
    }
  }
}
