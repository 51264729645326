import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'bridge-one-page',
  templateUrl: './one-page.component.html',
  styleUrls: ['./one-page.component.scss']
})
export class OnePageComponent implements AfterViewInit {

  @ViewChild('home', {static: false, read: ElementRef}) home: ElementRef;
  @ViewChild('termine', {static: false, read: ElementRef}) termine: ElementRef;
  @ViewChild('kontakte', {static: false, read: ElementRef}) kontakte: ElementRef;
  @ViewChild('anfahrt', {static: false, read: ElementRef}) anfahrt: ElementRef;

  private elements;

  constructor() {
  }

  scrollTo(tag: string) {
    const element = this.elements[tag];
    if (element) {
      window.scroll(0, element.nativeElement.offsetTop - (tag === 'home' ? 180 : 0));
    }
  }

  ngAfterViewInit() {
    this.elements = {
      home: this.home,
      termine: this.termine,
      kontakte: this.kontakte,
      anfahrt: this.anfahrt,
    };
  }
}
