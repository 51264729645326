import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bridgeTimeSpan',
})
export class BridgeTimeSpanPipe implements PipeTransform {
  transform(value: string, otherDateStr: string): any {
    const date = new Date(value);
    const otherDate = new Date(otherDateStr);
    const datesMatch = this.doDatesMatch(date, otherDate);

    return this.getDateString(date, true) + ' - ' + this.getDateString(otherDate, !datesMatch);
  }

  private getDateString(date: Date, withDate: boolean) {
    const dateStr = `${this.pad(date.getDate())}.${this.pad(date.getMonth() + 1)}.${date.getFullYear()}`;
    const timeStr = `${this.pad(date.getHours())}:${this.pad(date.getMinutes())} Uhr`;
    return withDate ? dateStr + ' ' + timeStr : timeStr;
  }

  private pad(value: any): string {
    return String(value).padStart(2, '0');
  }

  private doDatesMatch(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }
}
