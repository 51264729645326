import { Component, OnInit, ViewChild } from '@angular/core';
import { QuillViewComponent } from "ngx-quill";
import { CalendarEntry } from '../../domain/calendar-entry';
import { CalendarEntryApiService } from '../../services/calendar-entry-api.service';
import { SiteApiService } from "../../services/site-api.service";

@Component({
  selector: 'bridge-termine',
  templateUrl: './termine.component.html',
  styleUrls: ['./termine.component.scss'],
})
export class TermineComponent implements OnInit {

  entries: CalendarEntry[] = [
    {
      title: 'Sommerturnier',
      description: 'Beginn 14:00 Uhr mit Kaffee und Kuchen',
      time: '28.07.2024',
      deleteAt: new Date(2024, 6, 29).getTime(),
      location: "Kaiserhof, Unterbruch 6, 47877 Willich",
    }, {
      title: 'Vereinsfahrt nach Kleve',
      description: '',
      time: '30.08. - 01.09.2024',
      deleteAt: new Date(2024, 9, 2).getTime(),
      location: "",
    },
    {
      title: 'Tag der offenen Tür - Gelegenheit zum Schnuppern',
      description: '',
      time: '29.09.2024, 10:00 Uhr',
      deleteAt: new Date(2024, 9, 30).getTime(),
      location: "In unseren Club-Räumlichkeiten am Spülwall",
    },
    {
      title: 'Weihnachtsturnier',
      description: '',
      time: '11.12.2024, 14:00 Uhr',
      deleteAt: new Date(2024, 11, 12).getTime(),
      location: "Kolpinghaus Kempen",
    },
  ].filter(entry => entry.deleteAt >= new Date().getTime());
  @ViewChild('view', { static: false }) view: QuillViewComponent;

  constructor(
    private siteService: SiteApiService,
  ) {
  }

  ngOnInit() {
    this.siteService.getPageTermine().subscribe(page => {
      if (page && page.content && page.content.ops) {
        this.view.quillEditor.editor.applyDelta(page.content.ops);
      }
    });

    // this.calendarService.getAll().subscribe(termine => this.entries = termine && termine.sort((a, b) =>
    //   a.startTime - b.startTime));
  }

  getLines(text: string): string[] {
    return text && text.split('\n');
  }
}
