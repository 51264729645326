import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CalendarPage } from '../domain/calendar-page';
import { TextPage } from '../domain/text-page';

@Injectable({
  providedIn: 'root',
})
export class SiteApiService {

  private baseUrl = '/api/v1';
  private textConfig = {
    headers: {
      'Content-Type': 'text/html',
    }
  };

  private jsonConfig = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  constructor(private http: HttpClient) {
  }

  public getPageHome(): Observable<TextPage> {
    return this.http.get<TextPage>(`${this.baseUrl}/site/pages/home`).pipe(first());
  }

  public getPageKontakt(): Observable<TextPage> {
    return this.http.get<TextPage>(`${this.baseUrl}/site/pages/kontakt`).pipe(first());
  }

  public getPageTermine(): Observable<CalendarPage> {
    return this.http.get<CalendarPage>(`${this.baseUrl}/site/pages/termine`).pipe(first());
  }

  public getPageAnfahrt(): Observable<TextPage> {
    return this.http.get<TextPage>(`${this.baseUrl}/site/pages/anfahrt`).pipe(first());
  }

  public getSnippetHeader(): Observable<TextPage> {
    return this.http.get<TextPage>(`${this.baseUrl}/site/snippets/header`).pipe(first());
  }

  public updatePageHome(content: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/site/pages/home`, content).pipe(first());
  }

  public updatePageKontakt(content: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/site/pages/kontakt`, content).pipe(first());
  }

  public updatePageTermine(content: string): Observable<void> {
    console.log(content);
    return this.http.put<any>(`${this.baseUrl}/site/pages/termine`, content).pipe(first());
  }

  public updatePageAnfahrt(content: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/site/pages/anfahrt`, content).pipe(first());
  }

  public updateSnippetHeader(content: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/site/snippets/header`, content).pipe(first());
  }
}
