import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Id } from '../domain/domain';

export class ApiService<T> {

  private baseUrl = '/api/v1';

  constructor(private domainName: string, private http: HttpClient) {
    this.baseUrl += '/' + domainName;
  }

  public getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl);
  }

  public getById(id: Id): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`);
  }

  public create(domain: T): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}`, domain);
  }

  public update(domain: T): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}`, domain);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
