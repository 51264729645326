import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from "@angular/forms";
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { QuillModule } from "ngx-quill";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnfahrtComponent } from './components/anfahrt/anfahrt.component';
import { HomeComponent } from './components/home/home.component';
import { KontaktComponent } from './components/kontakt/kontakt.component';
import { KontakteComponent } from './components/kontakte/kontakte.component';
import { MenuComponent } from './components/menu/menu.component';
import { OnePageComponent } from "./components/one-page/one-page.component";
import { TermineComponent } from './components/termine/termine.component';
import { PipesModule } from './pipes/pipes.module';
import { SiteApiService } from './services/site-api.service';
import { ImpressumComponent } from './components/impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    AnfahrtComponent,
    KontaktComponent,
    KontakteComponent,
    TermineComponent,
    OnePageComponent,
    ImpressumComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    FlexLayoutModule,
    MatCardModule,
    PipesModule,
    MatFormFieldModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    FormsModule,
  ],
  providers: [SiteApiService],
  bootstrap: [AppComponent],
})
export class AppModule {
}
