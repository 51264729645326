import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../domain/contact';

@Component({
  selector: 'bridge-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss'],
})
export class KontaktComponent implements OnInit {

  @Input()
  image: string;
  @Input()
  contact: Contact;

  constructor() {
  }

  ngOnInit() {
  }

}
