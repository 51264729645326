import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bridgeDate',
})
export class BridgeDatePipe implements PipeTransform {

  transform(value: any, showUhr: boolean = false): any {
    const date = new Date(value);
    const res = `${this.pad(date.getDate())}.${this.pad(date.getMonth() + 1)}.${date.getFullYear()} ` +
      `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
    return showUhr ? res + ' Uhr' : res;
  }

  private pad(value: any): string {
    return String(value).padStart(2, '0');
  }
}
