import {Component, OnInit, ViewChild} from '@angular/core';
import {SiteApiService} from '../../services/site-api.service';
import {QuillViewComponent} from "ngx-quill";

@Component({
  selector: 'bridge-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
}
