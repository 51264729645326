import {Component} from '@angular/core';

@Component({
  selector: 'bridge-anfahrt',
  templateUrl: './anfahrt.component.html',
  styleUrls: ['./anfahrt.component.scss']
})
export class AnfahrtComponent {

}
