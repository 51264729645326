import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BridgeDatePipe} from './bridge-date.pipe';
import {BridgeTimeSpanPipe} from './bridge-time-span.pipe';

@NgModule({
  declarations: [BridgeDatePipe, BridgeTimeSpanPipe],
  exports: [
    BridgeDatePipe,
    BridgeTimeSpanPipe,
  ],
  imports: [
    CommonModule,
  ],
})
export class PipesModule {
}
