import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { OnePageComponent } from "./components/one-page/one-page.component";

const routes: Routes = [
  {
    path: '',
    component: OnePageComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
